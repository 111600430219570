@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(244, 244, 244, 1) 8%, rgba(240, 243, 255, 1) 63%, rgba(247, 225, 206, 1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin background-clip-text {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text-liberez {
  background: linear-gradient(
    90deg,
    rgba(198, 182, 217, 1) 12%,
    rgba(227, 201, 226, 1) 42%,
    rgba(52, 183, 192, 1) 83%,
    rgba(94, 192, 205, 1) 100%
  );
  @include background-clip-text;
}

.custom-color-text {
  background: linear-gradient(
    180deg,
    rgba(139, 194, 255, 1) 11%,
    rgba(166, 179, 238, 1) 32%,
    rgba(255, 143, 216, 1) 89%
  );
  @include background-clip-text;
}

.custom-color-text-2 {
  background: linear-gradient(
    180deg,
    rgba(255, 195, 141, 1) 3%,
    rgba(255, 156, 220, 1) 57%,
    rgba(255, 143, 216, 1) 86%
  );
  @include background-clip-text;
}

.custom-color-text-3 {
  background: linear-gradient(
    180deg,
    rgba(255, 177, 78, 1) 13%,
    rgba(255, 144, 90, 1) 44%,
    rgba(255, 155, 106, 1) 66%,
    rgba(255, 195, 141, 1) 100%
  );
  @include background-clip-text;
}

.custom-color-text-4 {
  background: linear-gradient(180deg, rgba(226, 159, 222, 1) 4%, rgba(0, 128, 255, 1) 57%, rgba(48, 67, 153, 1) 100%);
  @include background-clip-text;
}

.custom-color-text-5 {
  background: linear-gradient(
    180deg,
    rgba(138, 255, 187, 1) 1%,
    rgba(153, 233, 204, 1) 33%,
    rgba(131, 217, 226, 1) 75%,
    rgba(139, 194, 255, 1) 99%
  );
  @include background-clip-text;
}

/* STYLE BURGER MENU */
.bm-overlay {
  margin-top: 11% !important;
  background: none !important;
}

.bm-menu-wrap {
  position: fixed !important;
  height: 100% !important;
}

.bm-menu {
  position: sticky !important;
  margin-top: 19px !important;
  background: white !important;
  padding: 2.5em 1.5em 0 !important;
  font-size: 1.15em !important;
}

/*# sourceMappingURL=index.scss.map */
