@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgb(244, 244, 244) 8%, rgb(240, 243, 255) 63%, rgb(247, 225, 206) 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-color-text-liberez {
  background: linear-gradient(
    90deg,
    rgb(198, 182, 217) 12%,
    rgb(227, 201, 226) 42%,
    rgb(52, 183, 192) 83%,
    rgb(94, 192, 205) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text {
  background: linear-gradient(180deg, rgb(139, 194, 255) 11%, rgb(166, 179, 238) 32%, rgb(255, 143, 216) 89%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text-2 {
  background: linear-gradient(180deg, rgb(255, 195, 141) 3%, rgb(255, 156, 220) 57%, rgb(255, 143, 216) 86%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text-3 {
  background: linear-gradient(
    180deg,
    rgb(255, 177, 78) 13%,
    rgb(255, 144, 90) 44%,
    rgb(255, 155, 106) 66%,
    rgb(255, 195, 141) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text-4 {
  background: linear-gradient(180deg, rgb(226, 159, 222) 4%, rgb(0, 128, 255) 57%, rgb(48, 67, 153) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

.custom-color-text-5 {
  background: linear-gradient(
    180deg,
    rgb(138, 255, 187) 1%,
    rgb(153, 233, 204) 33%,
    rgb(131, 217, 226) 75%,
    rgb(139, 194, 255) 99%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
}

/* STYLE BURGER MENU */
.bm-overlay {
  margin-top: 11% !important;
  background: none !important;
}

.bm-menu-wrap {
  position: fixed !important;
  height: 100% !important;
}

.bm-menu {
  position: sticky !important;
  margin-top: 19px !important;
  background: white !important;
  padding: 2.5em 1.5em 0 !important;
  font-size: 1.15em !important;
}

